<template>
  <LayoutEditorToolbar :buttons="buttons" class="toolbar-style">
    <template v-slot:button="{ button }">
      <ToolButton
        :tooltip="button.tooltip"
        :icon="button.icon"
        :active="button.name === toolCategory"
        :color="button.color"
        :label="button.label"
        @clicked="() => onChangeToolCategory(button)"
        topTooltip
      ></ToolButton>
    </template>
  </LayoutEditorToolbar>
</template>

<script>
import ToolsMixin from "@/mixins/editors/ToolsMixin";

const CATEGORY = 0;
const TOOL = 1;
const ACTION = 2;

export default {
  mixins: [ToolsMixin],
  data() {
    return {
      more: false,
    };
  },
  computed: {
    buttons() {
      return [
        {
          tooltip: "",
          name: "scar-overgrowth",
          label: "Przerost",
          icon: require("@/assets/editor/uro/buttons/overgrowth.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-pain",
          label: "Ból",
          icon: require("@/assets/editor/uro/buttons/pain.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-vanishing",
          label: "Zanik",
          icon: require("@/assets/editor/uro/buttons/vanishing.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-scarring",
          label: "Bliznowiec",
          icon: require("@/assets/editor/uro/buttons/scarring.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-hypersensitivity",
          label: "Przeczulica",
          icon: require("@/assets/editor/uro/buttons/hypersensitivity.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-hyperinsensitivity",
          label: "Niedoczulica",
          icon: require("@/assets/editor/uro/buttons/hyperinsensitivity.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-hyperpigmentation",
          label: "Hiperpigmentacja",
          icon: require("@/assets/editor/uro/buttons/hyperpigmentation.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-reddening",
          label: "Zaczerwienienie",
          icon: require("@/assets/editor/uro/buttons/reddening.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-displacement",
          label: "Zaburzenie przesuwalności",
          icon: require("@/assets/editor/uro/buttons/displacement.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-bulge",
          label: "Wybrzuszenie",
          icon: require("@/assets/editor/uro/buttons/bulge.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-retraction",
          label: "Wciągnięcie",
          icon: require("@/assets/editor/uro/buttons/retraction.svg"),
          kind: TOOL,
          visible: true,
        },
        {
          tooltip: "",
          name: "scar-gluing",
          label: "Sklejenie",
          icon: require("@/assets/editor/uro/buttons/gluing.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-adhesion",
          label: "Zrost",
          icon: require("@/assets/editor/uro/buttons/adhesion.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-shine",
          label: "Błyszczenie",
          icon: require("@/assets/editor/uro/buttons/shine.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-pulling",
          label: "Ciągnięcie",
          icon: require("@/assets/editor/uro/buttons/pulling.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-itching",
          label: "Swędzenie",
          icon: require("@/assets/editor/uro/buttons/itching.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-scab",
          label: "Strup",
          icon: require("@/assets/editor/uro/buttons/scab.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-necrosis",
          label: "Martwica",
          icon: require("@/assets/editor/uro/buttons/necrosis.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "scar-electrodermal",
          label: "Zaburzenia elektrodermalne",
          icon: require("@/assets/editor/uro/buttons/electrodermal.svg"),
          kind: TOOL,
          visible: this.more,
        },
        {
          tooltip: "",
          name: "show",
          label: this.more ? "Pokaż mniej" : "Pokaż więcej",
          icon: require("@/assets/editor/uro/buttons/more.svg"),
          kind: ACTION,
          visible: true,
        },
      ].filter((item) => item.visible === true);
    },
  },
  components: {
    ToolButton: () => import("@/components/editors/ToolButton"),
    LayoutEditorToolbar: () =>
      import("@/components/editors/toolbars/LayoutEditorToolbar"),
  },
  methods: {
    onChangeToolCategory(button) {
      if (button.kind == TOOL) {
        this.changeToolCategory(button.name);
        this.changeTool(button.name);
      } else {
        this.more = !this.more;
      }
    },
  },
};
</script>

<style scoped class="css">
.toolbar-style {
  background-color: #f6f7fb !important;
}
.break {
  height: 1px !important;
  width: 100% !important;
}
</style>